import getConfig from "next/config";

import { PropertyAPIQueryParams } from "types";

// Only holds serverRuntimeConfig and publicRuntimeConfig
const { publicRuntimeConfig } = getConfig();

export const URL_BASE = `${publicRuntimeConfig.nextBase}/v1/`;
export const URL_BASE_V1_1 = `${publicRuntimeConfig.nextBase}/v1.1/`;
export const URL_BASE_V2 = `${publicRuntimeConfig.nextBase}/v2/`;
export const URL_RATE_BASE = publicRuntimeConfig.nextBase; // TODO: create base to api{number}.fora.travel
export const URL_ADMIN_USERS_LIST = `${publicRuntimeConfig.nextAdminPortal}/user/`;
export const URL_HOTELS = `${URL_BASE}hotels/`;
export const URL_HOTELS_SEARCH = `${URL_BASE}hotels/search/`;
export const URL_BRANDS_LEGACY = `${URL_BASE}brands/`;
export const URL_BRANDS = `${URL_BASE}supplier-database/supplier-brands/`;
export const URL_SUPPLIER_DATABASE = `${URL_BASE}supplier-database/`;
export const URL_SUPPLIERS = `${URL_SUPPLIER_DATABASE}suppliers/`;
export const URL_USER_PAYMENTS = `${URL_BASE}payments/`;
export const URL_USER_PAYMENTS_DOWNLOAD = `${URL_USER_PAYMENTS}download/`;
export const URL_USER_PAYMENTS_SUMMARY = `${URL_USER_PAYMENTS}summary/`;
export const URL_USER_PAYMENTS_TOTALS = `${URL_USER_PAYMENTS}totals/`;
export const URL_SUPPLIER_TYPES = `${URL_SUPPLIER_DATABASE}supplier-types/`;
export const URL_USER_PROFILE = `${URL_BASE}profile/`;
export const URL_USER_ONBOARDING = `${URL_BASE}profile/onboarding/`;
export const URL_DEFAULT_COMMISSION_PLAN = `${URL_BASE}default-commission-plan/`;
export const URL_COMMISSIONS_V2 = `${URL_BASE_V2}commissions/`;
export const URL_COMMISSIONS_V2_DOWNLOAD = `${URL_COMMISSIONS_V2}download/`;
export const URL_COMMISSIONS_V2_SUMMARIES = `${URL_COMMISSIONS_V2}summaries/`;
export const URL_REGISTRATION = `${URL_BASE}register/`;
export const URL_CHATBOT = `${URL_BASE}ai-conversations/`;
export const URL_CHATBOT_FEEDBACK = `${URL_BASE}ai-messages/`;
export const getURLRegisterValidation = (key: string) =>
    `${URL_BASE}register/${key}/profile_validation/`;
export const getReferralCodeValidate = (key: string) => `${URL_BASE}referral-codes/${key}/validate`;
export const URL_PHONE_CODES = `${URL_BASE}phone-codes/`;
export const URL_COUNTRIES = `${URL_BASE}countries/`;
export const URL_RESET_PASSWORD = `${URL_BASE}password-reset/`;
export const URL_RESET_PASSWORD_CONFIRM = `${URL_RESET_PASSWORD}confirm/`;
export const URL_RESET_PASSWORD_VALIDATE_TOKEN = `${URL_RESET_PASSWORD}validate_token/`;
export const URL_TIMEZONES = `${URL_BASE}timezones/`;
export const URL_HOTELPAGE_SVG = `${publicRuntimeConfig.staticHost}`;
export const URL_STATIC_PAGES = `${URL_BASE}static-pages/`;
export const URL_FEATURES = `${URL_BASE_V2}features/`;
export const URL_DASHBOARD = `${URL_BASE}dashboard/`;
export const URL_TRAININGS_TRACKS_V2 = `${URL_BASE_V2}tracks`;
export const URL_TRAININGS_LESSON_V2 = `${URL_BASE_V2}lessons/`;
export const URL_TRAININGS_GROUPS = `${URL_BASE_V2}groups/`;
export const URL_PERSONAL_STRIPE_CABINET = `${URL_BASE}profile/personal-stripe-cabinet/`;
export const URL_BANNERS_LIST = `${URL_BASE}banners/`;
export const URL_DASHBOARD_BOOKINGS = `${URL_BASE_V2}commissions/dashboard/`;
export const URL_VALIDATE_PHONE_NUMBER = `${URL_BASE}validate-phone/`;
export const URL_LOYALTY_PROGRAMS = `${URL_BASE}loyalty-programs`;
export const URL_LOYALTY_PROGRAMS_V2 = `${URL_BASE_V2}loyalty-programs`;
export const URL_SUPPLIER_PARTNER_PROGRAMS_LIST = `${URL_SUPPLIER_DATABASE}partner-programs/`;
export const URL_CALENDLY_SCHEDULING = publicRuntimeConfig.calendlySchedulingUrl;
export const getUrlSupplierProgramsByType = (slug: string) => `${URL_SUPPLIERS}${slug}/programs/`;
export const URL_CLIENTS = `${URL_BASE}clients/`;
export const URL_CLIENTS_V2 = `${URL_BASE_V2}clients/`;
export const URL_LEADS = `${URL_BASE}leads/`;
export const URL_LEAD_STATS = `${URL_BASE}leads/statistics`;
export const URL_ONBOARDING_TASKS = `${URL_BASE}user/onboarding-tasks/`;
export const URL_CALENDAR_EVENTS = `${URL_BASE}calendars/events/`;
export const URL_CALENDARS = `${URL_BASE}calendars/`;

export const getPdfApiUrl = (id: string) => `${URL_COMMISSIONS_V2}${id}/pdf/`;

export const URL_TRAVEL_REVIEW = `${URL_BASE}travel_review/`;
export const getSecureLinkAddCard = (uriLink: string) =>
    uriLink &&
    publicRuntimeConfig.secureLinkAddCard &&
    publicRuntimeConfig.secureLinkAddCard !== "undefined"
        ? `${publicRuntimeConfig.secureLinkAddCard}${uriLink}`
        : "Something went wrong. Try please again";
export const getUrlReassignClientOnBooking = (bookingId: string) =>
    `${URL_COMMISSIONS_V2}${bookingId}/reassign/`;

export const URL_SUPPLIER_RATES_V2 = `${URL_BASE_V2}supplier/rate_summary/`;
export const URL_SUPPLIER_RATES_V2_POSTFIX = `${URL_RATE_BASE}/v2/supplier/rate_summary/`;
export const URL_CURRENCIES_LIST = `${URL_BASE}currencies/`;
export const URI_PAYMENTS_INVITE = `${URL_BASE}payments/invite/`;
export const URL_MVT_TILES = `${URL_BASE}supplier/mvt-tiles/`;

export const URI_BASE = "/";
export const URI_HOME = "/home";
export const URI_GET_STARTED = "/get-started";
export const URI_LOGIN = "/login";
export const URI_LOGOUT = "/logout";
export const URI_ONBOARDING = "/onboarding";
export const URL_CANCELLATION_WORKFLOW = `${URL_BASE_V2}CancellationReasons/`;
export const URI_RESTORE_PASSWORD = "/restore-password";
export const URI_RESET_PASSWORD = "/reset-password";
export const URI_HOTELS = "/hotels";
export const URI_USERS = "/users";
export const URI_HOTEL_PERKS = "/hotel-perks";
export const URI_HOW_TO_BOOK = "/s/book-instructions";
export const URI_TRAINING_V2 = "/training";
export const URI_EVENTS = "/events";
export const URI_EVENTS_WEEK = `${URI_EVENTS}/week`;
export const URI_COMMISSIONS = "/bookings";
export const URI_COMMUNITY = "/community";
export const URI_FORUM_SHORTCUT = "/forum";
export const URI_USER_PROFILE = "/settings";
export const URI_SUPPLIERS = "/browse-book";
export const URI_PARTNERS_SUPPLIERS = "/partners";
export const URI_PAYMENTS = "/payments";
export const URI_SUPPLIERS_PROGRAMS = `${URI_PARTNERS_SUPPLIERS}/programs`;
export const URI_HOTELS_WITH_PERKS = `${URI_SUPPLIERS}/hotels-with-perks`;
export const URI_BOOKING_PLATFORM_HOTELS = `${URI_SUPPLIERS}/hotels`;
export const URI_PARTNERS = `${URI_PARTNERS_SUPPLIERS}/hotels`;
export const URI_PACKAGES = `${URI_PARTNERS_SUPPLIERS}/packages`;
export const URI_REGISTRATION_SUCCESS = "/register/success";
export const URI_SUPPLIERS_PERKS = "/s/suppliers-perks";
export const URI_RESOURCES = "/s/resources";
export const URI_HELP_PORTAL = "/help";
export const URI_CHATBOT = "/sidekick/";
export const URI_MARKETING = "/marketing";
export const URI_MARKETING_FORA_PROFILE = `${URI_MARKETING}/fora-profile`;
export const URI_FORA_PROFILE = "/fora-profile";
export const URI_WEBSITE_PROFILE = `${URI_FORA_PROFILE}/website-profile`;
export const URI_SHAREABLE_ASSETS = "/shareable-assets";
export const URI_BRAND_ASSETS = "/brand-assets";
export const URI_WHATS_NEW = "/whats-new";
export const URI_FORA_EMAIL = "/mail";
export const URI_CLIENTS = "/clients";
export const URI_POLICIES = "/policies";
export const URI_COMMUNITY_GUIDELINES = `${URI_POLICIES}/community-guidelines`;
export const URI_LEADS = "/leads";
export const URI_CLIENT_VIEW = `${URI_CLIENTS}/client-view`;
export const URI_CLIENT_VIEW_INFORMATION = `${URI_CLIENTS}/client-view-information`;
export const URI_CLIENT_ADD = `${URI_CLIENTS}/add-client`;
export const URI_SUPPLIER_RATE = `${URL_BASE}supplier/rate/`;
export const URI_SUPPLIER_BOOK = `${URL_BASE}supplier/book/`;
export const URI_ISSUES = `${URL_BASE}issues/`;
export const URI_REQUEST_FORM = `${URL_BASE}advisor-request/`;
export const URI_ISSUES_REASONS = `${URL_BASE}issue-reasons/`;
export const URI_ISSUES_CATEGORIES = `${URL_BASE}issue-categories/`;
export const URI_ISSUES_SEVERITIES = `${URL_BASE}issue-severities/`;
export const CONTENTFUL_BASE_URL = "https://graphql.contentful.com/content/v1/spaces/";
export const URI_EXTERNAL_ADVISOR_CRM = `${URL_BASE}advisor-to-external-crm/upsert/`;

export const FavoritesApiUri = {
    URI_USER_FAVORITES_LIST: `${URL_BASE}user-supplier-list/`,
    URI_USER_FAVORITES_LIST_SUPPLIER: `${URL_BASE}user-supplier-list/supplier/`,
    getUserFavoritesList: (uuid: string = "") =>
        `${URL_BASE}user-supplier-list/${uuid ? `${uuid}/` : ""}`,
    getUserFavoritesListSupplier: (uuid: string = "") =>
        `${URL_BASE}user-supplier-list/supplier/${uuid ? `${uuid}/` : ""}`
};

export const PropertyReportsApiUri = {
    URI_PROPERTY_REPORTS_LIST: `${URL_BASE}property-reports`,
    getSupplierPropertyReportsList: ({
        supplierId,
        isOwnReport = false,
        search = "",
        exclude = "",
        limit,
        offset,
        ordering = ""
    }: PropertyAPIQueryParams) =>
        `${URL_BASE}suppliers/${supplierId}/property-reports/?my=${isOwnReport}${
            search ? `&search=${search}` : ""
        }${exclude ? `&exclude=${exclude}` : ""}${limit ? `&limit=${limit}` : ""}${
            offset ? `&offset=${offset}` : ""
        }${ordering ? `&ordering=${ordering}` : ""}`,

    getPropertyReportById: (propertyReportId: string = "") =>
        `${PropertyReportsApiUri.URI_PROPERTY_REPORTS_LIST}${
            propertyReportId ? `/${propertyReportId}/` : ""
        }`,
    getPropertyReportByIdSubmit: (propertyReportId: string = "") =>
        `${PropertyReportsApiUri.getPropertyReportById(propertyReportId)}submit/`,
    getPropertyReportLike: (propertyReportId: string) =>
        `${PropertyReportsApiUri.URI_PROPERTY_REPORTS_LIST}/${propertyReportId}/like/`,
    getPropertyReportsFromAllSuppliers: ({
        isOwnReport = false,
        limit,
        offset
    }: PropertyAPIQueryParams) =>
        `${PropertyReportsApiUri.URI_PROPERTY_REPORTS_LIST}/?my=${isOwnReport}${
            limit ? `&limit=${limit}` : ""
        }${offset ? `&offset=${offset}` : ""}`
};
