import React, { useEffect, useState } from "react";
import { useSearchParams, usePathname } from "next/navigation";
import { Spinner } from "phosphor-react";
import { DateTime } from "luxon";
import { Formik } from "formik";
import * as Yup from "yup";
import { datadogLogs } from "@datadog/browser-logs";
import {
    URI_ISSUES,
    URI_ISSUES_REASONS,
    URI_ISSUES_CATEGORIES,
    URI_ISSUES_SEVERITIES
} from "constants/urls";
import { useAuth } from "hooks/use-auth";
import { getData, postDataWithStatus } from "utils/http-requests";
import {
    trackReportIssueCategoryClicked,
    trackReportIssueTypeClicked,
    trackReportIssueSeverityClicked,
    trackReportIssueCancelClicked,
    trackReportIssueSubmitClicked
} from "analytics/issues/report-issue-analytics";
import {
    IIssueCategory,
    IIssueType,
    IIssueSeverity,
    ReportIssueMetadata
} from "lib/types/issues.types";
import { useModalManager } from "hooks/use-modal-manager";
import { getURL } from "utils/url";
import { EndScreen } from "components/form/pages/EndScreen";
import { InputError } from "components/common/v2/InputError";
import Loader from "components/common/Loader";
import { Modal } from "components/common/v2/modal/Modal";
import { Textarea } from "components/common/v2/Textarea";
import { SingleSelect } from "components/common/v2/select";

interface FormValues {
    category: string | undefined;
    issueType: string | undefined;
    issueSeverity: string | undefined;
    issueDescription: string | undefined;
}

const pathMappingsToCategory = {
    "/help": "booking",
    "/partners": "booking",
    "/bookings": "booking",
    "/training": "training",
    "/marketing": "marketing",
    "/s/resources": "marketing",
    "/payments": "payments"
};

const getInitialCategory = (path: string | null): string | undefined => {
    for (const key in pathMappingsToCategory) {
        if (path?.startsWith(key)) {
            return pathMappingsToCategory[key as keyof typeof pathMappingsToCategory];
        }
    }
    return;
};

const DEFAULT_SEVERITY = "normal";

// sort alphabetically but force other to be last
const customIssueCategorySort = (a: IIssueCategory, b: IIssueCategory): number => {
    if (a.category === "other") return 1; // 'other' goes last
    if (b.category === "other") return -1; // 'other' goes last
    return a.display_text.localeCompare(b.display_text);
};

// sort alphabetically but force other to be last
const customIssueTypeSort = (a: IIssueType, b: IIssueType): number => {
    if (a.display_text.startsWith("Other")) return 1; // 'other' goes last
    if (b.display_text.startsWith("Other")) return -1; // 'other' goes last
    return a.display_text.localeCompare(b.display_text);
};

const validationSchema = Yup.object().shape({
    category: Yup.string().required("Topic is required").typeError("Topic is required"),
    issueType: Yup.string().required("Type is required").typeError("Type is required"),
    issueSeverity: Yup.string().required("Severity is required").typeError("Severity is required"),
    issueDescription: Yup.string().required("Details are required")
});

export const ReportIssueModal = () => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const { closeModal } = useModalManager();
    const { token, userProfile } = useAuth();
    const base_url = window.location.origin;

    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [issueCategoryList, setIssueCategoryList] = useState<IIssueCategory[]>([]);
    const [issueSeverityList, setIssueSeverityList] = useState<IIssueSeverity[]>([]);
    const [issueCategoryToIssueList, setCategoryToIssueList] = useState<{
        string: IIssueType[];
    } | null>(null);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showFailure, setShowFailure] = useState<boolean>(false);
    const [isModalSubmitting, setIsModalSubmitting] = useState<boolean>(false);

    const getCategoryById = (categoryId: string | undefined): IIssueCategory | undefined => {
        if (!categoryId) return;
        return issueCategoryList.find(({ id }: IIssueCategory) => id === categoryId);
    };

    const getIssuesByCategory = (category: IIssueCategory | undefined): IIssueType[] => {
        if (!category?.category) return [];
        return (
            issueCategoryToIssueList?.[
                category.category as keyof typeof issueCategoryToIssueList
            ] || []
        );
    };

    const defaultCategory = getInitialCategory(pathname);
    const initialValues = {
        category:
            issueCategoryList.find(({ category }: IIssueCategory) => category === defaultCategory)
                ?.id ?? "",
        issueType: "",
        issueSeverity:
            issueSeverityList.find(
                (severity: IIssueSeverity) => severity.severity === DEFAULT_SEVERITY
            )?.id ?? "",
        issueDescription: ""
    };
    useEffect(() => {
        const getIssueTypeList = async () => {
            try {
                const response = await getData(URI_ISSUES_REASONS, token);
                // group issues by category
                const transformedObject = response.reasons.reduce((acc: any, issue: IIssueType) => {
                    const categoryName = issue.category;
                    if (!acc[categoryName]) {
                        acc[categoryName] = [];
                    }
                    acc[categoryName].push(issue);
                    return acc;
                }, {} as Record<string, IIssueType[]>);
                // sort issue types alphabetically but force other as last
                for (const key in transformedObject) {
                    if (transformedObject.hasOwnProperty(key)) {
                        transformedObject[key].sort(customIssueTypeSort);
                    }
                }

                setCategoryToIssueList(transformedObject);
            } catch (e) {
                setShowFailure(true);
                datadogLogs.logger.error("Error getting issue reasons", {
                    error: e
                });
                console.error(e);
            }
        };

        const getIssueCategoryList = async () => {
            try {
                const response = await getData(URI_ISSUES_CATEGORIES, token);
                const sortedCategoriesList = response.categories.sort(customIssueCategorySort);
                setIssueCategoryList(sortedCategoriesList);
            } catch (e) {
                setShowFailure(true);
                datadogLogs.logger.error("Error getting issue categories", {
                    error: e
                });
                console.error(e);
            }
        };

        const getIssueSeverityList = async () => {
            try {
                const response = await getData(URI_ISSUES_SEVERITIES, token);
                setIssueSeverityList(response.severities);
            } catch (e) {
                setShowFailure(true);
                datadogLogs.logger.error("Error getting issue severities", {
                    error: e
                });
                console.error(e);
            }
        };
        getIssueTypeList();
        getIssueCategoryList();
        getIssueSeverityList();
    }, [token]);

    const getListOfIssuesForCategory = (categoryId: string | undefined) => {
        if (!categoryId) {
            return [];
        }
        const currentCategoryObj = getCategoryById(categoryId);
        return (
            issueCategoryToIssueList?.[
                currentCategoryObj?.category as keyof typeof issueCategoryToIssueList
            ] || []
        );
    };

    const getSubmitData = ({
        categoryId,
        typeId,
        severityId
    }: {
        categoryId?: string;
        typeId?: string;
        severityId?: string;
    }): { categoryData?: IIssueCategory; typeData?: IIssueType; severityData?: IIssueSeverity } => {
        const issueTypeList = getListOfIssuesForCategory(categoryId);
        const categoryData = getCategoryById(categoryId);
        const typeData = issueTypeList.find(({ id }: IIssueType) => id === typeId);
        const severityData = issueSeverityList.find(({ id }: IIssueSeverity) => id === severityId);
        return {
            categoryData,
            typeData,
            severityData
        };
    };
    const constructMetadata = ({
        categoryData,
        typeData,
        severityData,
        description
    }: {
        categoryData?: IIssueCategory;
        typeData?: IIssueType;
        severityData?: IIssueSeverity;
        description?: string;
    }): ReportIssueMetadata => ({
        advisor_email: userProfile?.email,
        advisor_name: `${userProfile?.firstName} ${userProfile?.lastName}`,
        advisor_id: `${userProfile?.id}`,
        // issue metadata
        issue_category: categoryData?.category || null,
        issue_category_display_name: categoryData?.display_text || null,
        issue_type: typeData?.slug || null,
        issue_type_display_name: typeData?.display_text || null,
        issue_severity: severityData?.severity || null,
        issue_severity_display_name: severityData?.display_text || null,
        issue_description: description || null,
        dateTime: DateTime.now()
    });

    const getMetadataFromValues = (values: {
        category?: string;
        issueType?: string;
        issueSeverity?: string;
        description?: string;
    }): ReportIssueMetadata =>
        constructMetadata({
            ...getSubmitData({
                categoryId: values.category,
                typeId: values.issueType,
                severityId: values.issueSeverity
            }),
            description: values.description
        });

    async function reportIssue(values: FormValues) {
        const { category, issueType, issueSeverity, issueDescription } = values;

        if (category && issueCategoryList && issueType && issueSeverity && issueSeverityList) {
            setIsModalSubmitting(true);
            const data = getSubmitData({
                categoryId: values.category,
                typeId: values.issueType,
                severityId: values.issueSeverity
            });
            const fullMetadata = constructMetadata({
                ...data,
                description: values.issueDescription
            });
            trackReportIssueSubmitClicked(fullMetadata);
            try {
                const newSearchParams = new URLSearchParams(searchParams ?? "");
                newSearchParams.delete("modal");
                const url = getURL(`${base_url}${pathname}`, newSearchParams);
                await postDataWithStatus(
                    `${URI_ISSUES}`,
                    {
                        reason_id: data.typeData?.id,
                        comment: issueDescription,
                        url,
                        metadata: fullMetadata,
                        category: data.categoryData?.id,
                        severity: data.severityData?.id
                    },
                    token
                ).then(({ response }) => {
                    if (response.status >= 200 && response.status < 300) {
                        setIsModalSubmitting(false);
                        setShowSuccess(true);
                    } else if (response.status >= 400) {
                        setIsModalSubmitting(false);
                        setShowFailure(true);
                        datadogLogs.logger.error(`Error submitting issue`, {
                            "Issue Category": data.categoryData,
                            "Issue Type": data.typeData,
                            "Issue Severity": data.severityData,
                            "Issue Description": issueDescription,
                            "Advisor Email": userProfile.email,
                            "Advisor Name": `${userProfile.firstName} ${userProfile.lastName}`,
                            "Advisor Id": `${userProfile.id}`
                        });
                    }
                });
            } catch (e) {
                setIsModalSubmitting(false);
                setShowFailure(true);
                datadogLogs.logger.error(`Error submitting issue`, {
                    "Issue Category": data.categoryData,
                    "Issue Type": data.typeData,
                    "Issue Severity": data.severityData,
                    "Issue Description": issueDescription,
                    "Advisor Email": userProfile.email,
                    "Advisor Name": `${userProfile.firstName} ${userProfile.lastName}`,
                    "Advisor Id": `${userProfile.id}`
                });
                console.error(e);
            }
        }
    }

    function handleCancel(values: FormValues) {
        closeModal();
        const metadata = getMetadataFromValues(values);
        trackReportIssueCancelClicked(metadata);
    }

    const isDoneLoading =
        issueCategoryList.length > 0 &&
        issueSeverityList.length > 0 &&
        issueCategoryToIssueList != null;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => reportIssue(values)}
            validateOnChange={true}
            enableReinitialize={true}
        >
            {formik => {
                const { errors, setFieldValue, handleSubmit, isSubmitting, values, validateForm } =
                    formik;
                const { category, issueType, issueSeverity, issueDescription } = values;
                const categoryObject = issueCategoryList.find(
                    ({ id }: IIssueCategory) => id === category
                );
                const issueTypeList = getIssuesByCategory(categoryObject);
                return (
                    <Modal
                        onClose={() => handleCancel(values)}
                        title="Report an issue"
                        open={true}
                        footerButtons={
                            showSuccess
                                ? {
                                      primary: {
                                          children: "Close",
                                          onClick: () => closeModal()
                                      }
                                  }
                                : {
                                      primary: {
                                          children: (
                                              <>
                                                  Report
                                                  {isSubmitting && (
                                                      <Spinner className="animate-[spin_1.5s_ease-in-out_infinite] text-[24px]" />
                                                  )}
                                              </>
                                          ),
                                          onClick: () => {
                                              setShowErrors(true);
                                              handleSubmit();
                                          },
                                          type: "submit",
                                          disabled: isModalSubmitting
                                      },
                                      secondary: {
                                          children: "Cancel",
                                          onClick: () => handleCancel(values)
                                      }
                                  }
                        }
                    >
                        {!isDoneLoading && !showSuccess && !showFailure ? (
                            <div data-testid="loader" className="px-6 py-8 w-full">
                                <Loader />
                            </div>
                        ) : showSuccess ? (
                            <EndScreen
                                title="Thank you for reporting!"
                                description="Your feedback helps us improve our product"
                                status="success"
                            />
                        ) : (
                            <>
                                <p className="text-medium text-secondaryDark font-normal text-left">
                                    We’re sorry to hear you’re facing issues. Please let us know the
                                    details and we’ll work to resolve it.
                                </p>
                                <div className="mb-6">
                                    <SingleSelect
                                        label="Issue topic"
                                        required
                                        value={category}
                                        placeholder="Select issue topic"
                                        onChange={value => {
                                            const cat = issueCategoryList.find(
                                                ({ id }: IIssueCategory) => id === value
                                            );
                                            setFieldValue("category", value);
                                            const currentIssueTypeList = getIssuesByCategory(cat);
                                            setFieldValue(
                                                "issueType",
                                                getIssuesByCategory(cat)?.length === 1
                                                    ? currentIssueTypeList?.[0]?.id
                                                    : undefined,
                                                true
                                            );
                                            if (cat) {
                                                trackReportIssueCategoryClicked(cat);
                                            }
                                            // Hack to fix the bug where form was not clearing error the first time only for category
                                            validateForm({ ...values, category: value });
                                        }}
                                        options={issueCategoryList.map(
                                            (issueCategory: IIssueCategory) => ({
                                                value: issueCategory.id,
                                                name: issueCategory.display_text
                                            })
                                        )}
                                        error={showErrors ? errors.category : ""}
                                    />
                                </div>
                                {issueTypeList && issueTypeList.length > 1 && category && (
                                    <div className="mb-6">
                                        <SingleSelect
                                            label="Issue type"
                                            required
                                            value={issueType}
                                            placeholder="Select issue type"
                                            onChange={value => {
                                                const issue = issueTypeList.find(
                                                    ({ id }: IIssueType) => id === value
                                                );
                                                setFieldValue("issueType", value, true);
                                                if (issue) {
                                                    trackReportIssueTypeClicked(issue);
                                                }
                                            }}
                                            options={issueTypeList.map((issueType: IIssueType) => ({
                                                value: issueType.id,
                                                name: issueType.display_text
                                            }))}
                                            error={showErrors ? errors.issueType : ""}
                                        />
                                    </div>
                                )}
                                <div className="mb-6">
                                    <SingleSelect
                                        label="Issue severity"
                                        required
                                        value={issueSeverity}
                                        placeholder="Select issue severity"
                                        onChange={value => {
                                            const severity = issueSeverityList.find(
                                                ({ id }: IIssueSeverity) => id === value
                                            );
                                            setFieldValue("issueSeverity", value, true);
                                            if (severity) {
                                                trackReportIssueSeverityClicked(severity);
                                            }
                                        }}
                                        options={issueSeverityList.map(
                                            (issueSeverity: IIssueSeverity) => ({
                                                value: issueSeverity.id,
                                                name: issueSeverity.display_text
                                            })
                                        )}
                                        error={showErrors ? errors.issueSeverity : ""}
                                    />
                                </div>
                                <div className="mb-8">
                                    <Textarea
                                        required
                                        label="Describe the issue"
                                        onChange={val =>
                                            setFieldValue("issueDescription", val, true)
                                        }
                                        placeholder="Please provide specific details about the issue. The more details you provide, the better we can address the problem and improve the experience."
                                        error={showErrors ? errors.issueDescription : ""}
                                        value={issueDescription}
                                    />
                                </div>
                                <div>
                                    {showFailure && (
                                        <InputError className="mr-2">
                                            There was an unexpected error, please try again
                                        </InputError>
                                    )}
                                </div>
                            </>
                        )}
                    </Modal>
                );
            }}
        </Formik>
    );
};
