import React, { useState, createContext, ReactNode } from "react";
import ModalCore from "./ModalCore";

interface ModalProviderProps {
    children: React.ReactNode;
}

const ModalContext: React.Context<ModalContextType> = createContext<ModalContextType>({
    modalComponent: undefined,
    setModalComponent: () => {}
});

const { Provider } = ModalContext;

const ModalProvider = ({ children }: ModalProviderProps) => {
    const [modalComponent, setModalComponent] = useState<ReactNode>();

    return (
        <Provider value={{ modalComponent, setModalComponent }}>
            <ModalCore isOpen={!!modalComponent} setIsOpen={() => setModalComponent(undefined)}>
                {modalComponent}
            </ModalCore>
            {children}
        </Provider>
    );
};

export { ModalContext, ModalProvider };
