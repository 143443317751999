import useSWR from "swr";
import Loader from "components/common/Loader";
import { SingleSelect } from "../select";
import { SingleSelectProps } from "../select/types";

type Props = SingleSelectProps & {
    url: string;
    parseResult: (data: any) => { value: string; name: string }[];
};

export const DataboundTypedown = ({
    url,
    parseResult = (data: any) =>
        (data?.results || []).map(({ id, name }: { id: string; name: string }) => ({
            value: id,
            name
        })),
    ...otherProps
}: Props) => {
    const { data, isLoading, error } = useSWR(url);
    const options = parseResult(data);
    if (error) {
        return "Error";
    }
    return (
        <div className="relative">
            {isLoading && (
                <div className="absolute top-8 md:top-10 left-4 z-10">
                    <Loader />
                </div>
            )}

            <SingleSelect {...otherProps} options={options} />
        </div>
    );
};
